#from __future__ import print_function, division
#input=raw_input

import random

#goal = #sample(range(10), 4)  #goal = list(str(random.randint(1000, 10000))) does not allow 0 at the beginning
#goal = list(str(goal)) #[str(x) for x in goal]
goal = []

def init_goal():
    global goal
    goal = []
    pool = list(range(10))
    while len(goal) < 4:
        i = random.randint(0, len(pool)-1)
        goal.append(str(pool[i]))
        pool.remove(pool[i])

#print(goal)

def list_count(lst, elem):
    res = 0
    for x in lst:
        if x == elem: res += 1
    return res

def mastermind(guess):
    result = []
    guess = list(guess)
    for x in goal:
        if x in guess:
            if guess.index(x) == goal.index(x):
                result.append(2)
            if guess.index(x) != goal.index(x): #Stef prefers else :(
                result.append(1)
    print("You have " + str(list_count(result, 2)) + " cows and " + str(list_count(result, 1)) + " bulls.")
    return result

def check(guess):
    if cheat:   #form ok like this for booleans
        return True
    if len(guess) != 4:
        print("Bastard! You want to cheat? Better try again!")
        return False
    for x in guess:
        if x not in "0123456789":
            print("Don't you use bullshit here! Or cowshit either!")
            return False
        if list_count(guess, x) > 1:
            print("No dooplicates welcome!")
            return False
    return True

count = 0   #counter variable
cheat = False
def turn(guess):
    global count, cheat #global variables welcomed inside
    if guess == "I give up..":
        print("The solution is: " + "".join(goal) + ". Loser!")
        return False
    if guess == "Abrakadabra!":
        print("The solution is: " + "".join(goal) + ". Cheater!")
        return True
    if guess == "Steflon:*":
        cheat = True
        print("Hope you feel better now!")
        return True
    if guess == "Restart":
        restart()
        return True
    if not check(guess): #if check() is False:
        return True
    res = mastermind(guess)
    count += 1   #concise for count = count + 1
    if list_count(res, 2) == 4:
        if count == 1:
            print("Beginner's luck or cheating?")
        elif count > 20:
            print("Finally.. Took you " + str(count) + " guesses! Not the smartest, eh?")
        else:
            print("Took you " + str(count) + " guesses! Congrats :)")
        return False
    return True

def restart():
    global count, cheat, over
    init_goal()
    over = False
    cheat = False
    count = 0
    print("You have restarted the game.")
    print("Guess a number: ")

over = False
def _input(val, key):
    global over
    if key == 13:
        document.getElementById('inp').value = ''
        print(val)
        if over:
            if val == "Restart":
                restart()
            else:
                print("The game is over. Type 'Restart' to restart.")
        elif (turn(val)):
            print("Guess a number: ")
        else:
            over = True
            print("Restart?")

init_goal()
print("Guess a number: ")

#while True:
#    guess = input("Guess a number: ")
#    if not turn(guess):
#        break

