	var __all__ = {};
	var __world__ = __all__;
	
	// Nested object creator, part of the nesting may already exist and have attributes
	var __nest__ = function (headObject, tailNames, value) {
		// In some cases this will be a global object, e.g. 'window'
		var current = headObject;
		
		if (tailNames != '') {	// Split on empty string doesn't give empty list
			// Find the last already created object in tailNames
			var tailChain = tailNames.split ('.');
			var firstNewIndex = tailChain.length;
			for (var index = 0; index < tailChain.length; index++) {
				if (!current.hasOwnProperty (tailChain [index])) {
					firstNewIndex = index;
					break;
				}
				current = current [tailChain [index]];
			}
			
			// Create the rest of the objects, if any
			for (var index = firstNewIndex; index < tailChain.length; index++) {
				current [tailChain [index]] = {};
				current = current [tailChain [index]];
			}
		}
		
		// Insert it new attributes, it may have been created earlier and have other attributes
		for (var attrib in value) {
			current [attrib] = value [attrib];			
		}		
	};
	__all__.__nest__ = __nest__;
	
	// Initialize module if not yet done and return its globals
	var __init__ = function (module) {
		if (!module.__inited__) {
			module.__all__.__init__ (module.__all__);
			module.__inited__ = true;
		}
		return module.__all__;
	};
	__all__.__init__ = __init__;
	
	// Since we want to assign functions, a = b.f should make b.f produce a bound function
	// So __get__ should be called by a property rather then a function
	// Factory __get__ creates one of three curried functions for func
	// Which one is produced depends on what's to the left of the dot of the corresponding JavaScript property
	var __get__ = function (self, func, quotedFuncName) {
		if (self) {
			if (self.hasOwnProperty ('__class__') || typeof self == 'string' || self instanceof String) {			// Object before the dot
				if (quotedFuncName) {									// Memoize call since fcall is on, by installing bound function in instance
					Object.defineProperty (self, quotedFuncName, {		// Will override the non-own property, next time it will be called directly
						value: function () {							// So next time just call curry function that calls function
							var args = [] .slice.apply (arguments);
							return func.apply (null, [self] .concat (args));
						},				
						writable: true,
						enumerable: true,
						configurable: true
					});
				}
				return function () {									// Return bound function, code dupplication for efficiency if no memoizing
					var args = [] .slice.apply (arguments);				// So multilayer search prototype, apply __get__, call curry func that calls func
					return func.apply (null, [self] .concat (args));
				};
			}
			else {														// Class before the dot
				return func;											// Return static method
			}
		}
		else {															// Nothing before the dot
			return func;												// Return free function
		}
	}
	__all__.__get__ = __get__;
			
	// Mother of all metaclasses		
	var py_metatype = {
		__name__: 'type',
		__bases__: [],
		
		// Overridable class creation worker
		__new__: function (meta, name, bases, attribs) {
			// Create the class cls, a functor, which the class creator function will return
			var cls = function () {						// If cls is called with arg0, arg1, etc, it calls its __new__ method with [arg0, arg1, etc]
				var args = [] .slice.apply (arguments);	// It has a __new__ method, not yet but at call time, since it is copied from the parent in the loop below
				return cls.__new__ (args);				// Each Python class directly or indirectly derives from object, which has the __new__ method
			};											// If there are no bases in the Python source, the compiler generates [object] for this parameter
			
			// Copy all methods, including __new__, properties and static attributes from base classes to new cls object
			// The new class object will simply be the prototype of its instances
			// JavaScript prototypical single inheritance will do here, since any object has only one class
			// This has nothing to do with Python multiple inheritance, that is implemented explictly in the copy loop below
			for (var index = bases.length - 1; index >= 0; index--) {	// Reversed order, since class vars of first base should win
				var base = bases [index];
				for (var attrib in base) {
					var descrip = Object.getOwnPropertyDescriptor (base, attrib);
					Object.defineProperty (cls, attrib, descrip);
				}			
__pragma__ ('ifdef', '__esv6__')

				for (var symbol of Object.getOwnPropertySymbols (base)) {
					var descrip = Object.getOwnPropertyDescriptor (base, symbol);
					Object.defineProperty (cls, symbol, descrip);
				}
				
__pragma__ ('endif')
			}
			
			// Add class specific attributes to the created cls object
			cls.__metaclass__ = meta;
			cls.__name__ = name;
			cls.__bases__ = bases;
			
			// Add own methods, properties and own static attributes to the created cls object
			for (var attrib in attribs) {
				var descrip = Object.getOwnPropertyDescriptor (attribs, attrib);
				Object.defineProperty (cls, attrib, descrip);
			}
__pragma__ ('ifdef', '__esv6__')

			for (var symbol of Object.getOwnPropertySymbols (attribs)) {
				var descrip = Object.getOwnPropertyDescriptor (attribs, symbol);
				Object.defineProperty (cls, symbol, descrip);
			}
			
__pragma__ ('endif')
			// Return created cls object
			return cls;
		}
	};
	py_metatype.__metaclass__ = py_metatype;
	__all__.py_metatype = py_metatype;
	
	// Mother of all classes		
	var object = {
		__init__: function (self) {},
		
		__metaclass__: py_metatype,	// By default, all classes have metaclass type, since they derive from object
		__name__: 'object',
		__bases__: [],
			
		// Object creator function is inherited by all classes (so in principle it could be made global)
		__new__: function (args) {	// Args are just the constructor args		
			// In JavaScript the Python class is the prototype of the Python object
			// In this way methods and static attributes will be available both with a class and an object before the dot
			// The descriptor produced by __get__ will return the right method flavor
			var instance = Object.create (this, {__class__: {value: this, enumerable: true}});
			
			// Call constructor
			this.__init__.apply (null, [instance] .concat (args));
			
			// Return instance
			return instance;
		}	
	};
	__all__.object = object;
	
	// Class creator facade function, calls class creation worker
	var __class__ = function (name, bases, attribs, meta) {			// Parameter meta is optional
		if (meta == undefined) {
			meta = bases [0] .__metaclass__;
		}
				
		return meta.__new__ (meta, name, bases, attribs);
	}
	__all__.__class__ = __class__;
	
	// Define __pragma__ to preserve '<all>' and '</all>', since it's never generated as a function, must be done early, so here
	var __pragma__ = function () {};
	__all__.__pragma__ = __pragma__;
	
	